import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import config from '../../config/website'
import { colors } from '../../tailwind'

// Components
import Menu from '../components/Menu'
import Layout from '../components/Layout'
import TagList from '../components/TagList'
import Persona from '../components/Persona'
import Footer from '../components/Footer'

// Elements
import {
  ProjectInner,
  HeaderBigBox,
  BigBox,
  ImageContainer,
  ImageContainerFull,
  ProjectHeader,
  ProjectDescription,
  ProjectDate,
  ProjectTitle,
  ProjectRow,
  ProjectRowPersona,
  ProjectSection,
  ProjectSectionTitle,
  ProjectSectionText,
} from '../elements/Projects'
import { StyledLinkOut } from '../elements/Links'
import NextProject from '../components/NextProject'

// Media
import DesigningGif from '../images/tempo/tempo-designing.gif'
import TempoDemoVideo from '../videos/tempo/tempo-demo.mp4'

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-44%, -59%);
`

const theme = {
  menu: colors.black,
  menuHover: colors.white,
  accent: colors['am-blue'],
  ally: colors['am-blue-ally'],
}

const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
const homeURL = `${config.siteUrl}${realPrefix}`
const image = `${homeURL}/tempo.jpg`

const Page = ({ data }) => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Helmet>
        <meta property="og:image" content={image} />
      </Helmet>
      <Menu color={colors.white} />
      <HeaderBigBox>
        <ProjectInner>
          <ImageContainer>
            <Img fluid={data.Tempo.childImageSharp.fluid} />
          </ImageContainer>
        </ProjectInner>
      </HeaderBigBox>
      <ProjectInner>
        <ProjectHeader>
          <ProjectDate>Winter 2017</ProjectDate>
          <ProjectTitle>Temp°</ProjectTitle>
          <ProjectDescription>
            Develop a showcase for the Weather Underground App for South By Southwest (SXSW) in one week.
          </ProjectDescription>
          <TagList tags={['Research', 'UI', 'UX', 'iPad']} />
        </ProjectHeader>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Inception</ProjectSectionTitle>
            <ProjectSectionText>
              Temp° began at IBM as a simple idea to combine music and weather in order to get people excited about an
              app called{' '}
              <StyledLinkOut href="https://www.wunderground.com/" target="__blank" rel="noopener noreferrer">
                Weather Underground
              </StyledLinkOut>
              . Temp° uses the Weather Underground API to create music out of data from a 10-day live forecast of
              specific areas around the world by assigning a music beat to 5 different elements within the data.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>My contribution</ProjectSectionTitle>
            <ProjectSectionText>
              Temp° was designed and delivered in one week by the IBM Maelstrom internship cohort which consisted of 11
              interns ranging in Design Research, UX, UI, and Front-end Development. I focused on UX and assisted in UI.
              I also worked closely with IBM project stakeholders to ensure the project stayed within the visual
              guidelines of IBM’s SXSW Exhibit.
            </ProjectSectionText>
          </ProjectSection>
          <ProjectSection>
            <ProjectSectionTitle>Tools</ProjectSectionTitle>
            <ProjectSectionText>
              <ul>
                <li>Good Ol' Paper n Pen</li>
                <li>Photoshop</li>
                <li>Garage Band</li>
              </ul>
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
      </ProjectInner>
      <ImageContainerFull>
        <Img fluid={data.group.childImageSharp.fluid} />
      </ImageContainerFull>
      <ProjectInner>
        <ProjectRowPersona>
          <Persona
            name="Stuart"
            age={25}
            job="Dev Ops Engineer"
            says="“Show me what you got.”"
            thinks="I wonder how helpful this will be for my work."
            feels="Frustrated when I dont understand."
            does="Wont stand in long lines, wants to learn as much as possible."
            imageUrl={data.stuart.childImageSharp.fixed}
          />
        </ProjectRowPersona>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Empathy Mapping</ProjectSectionTitle>
            <ProjectSectionText>
              Our team began the process with creating Stuart to frame our end product. We created a persona map to
              document what Stuart is thinking, feeling, saying, and doing.
              <ProjectSectionText>
                Stuart is a tech junkie with a development background. He's attending SXSW to ensure he's up to par with
                current technology. He talks fast and likes to learn a ton. He plans to see as much as possible at SXSW
                so he won't spend a lot of time at one exhibit.
              </ProjectSectionText>
              <ProjectSectionText>
                I'm happy to report that I met quite a few Stuart-esque SXSW participants while presenting Temp°.
              </ProjectSectionText>
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ImageContainer>
            <img src={DesigningGif} alt="Design Thinking" />
          </ImageContainer>
        </ProjectRow>
      </ProjectInner>
      <BigBox>
        <ProjectInner>
          <ImageContainer style={{ margin: '4rem auto' }}>
            <Img fluid={data.ipad.childImageSharp.fluid} />
            <Video autoPlay muted loop controls playsinline>
              <track kind="captions" />
              <source src={TempoDemoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </ImageContainer>
        </ProjectInner>
      </BigBox>
      <ProjectInner>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Making music</ProjectSectionTitle>
            <ProjectSectionText>
              We synced five parameters in current weather data (dew point, temperature, location, humidity, and wind
              speed) with customized musical beats for five geographical locations. As the weather parameters evolve
              over the day, the volume levels of their respective audio tracks will rise and fall, changing the music
              over time.
            </ProjectSectionText>
            <ProjectSectionText>
              In order to showcase Weather Underground’s personal weather stations very location specific data, we show
              three areas around the SXSW location in Austin. We added Antarctica to show some of the harshest cold
              conditions and Tokyo because it was relatable to a high volume of SXSW visitors.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
      </ProjectInner>
      <ImageContainerFull>
        <Img fluid={data.using.childImageSharp.fluid} />
      </ImageContainerFull>
      <ProjectInner>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Potential</ProjectSectionTitle>
            <ProjectSectionText>
              Imagine waking up to custom music allowing you to discover what the weather will be like that day. On your
              drive home from work, listen to the melodies of weather. By adding elements like elevation and speed into
              the mix, you can discover what music sounds like at 30,000 feet up in the sky on your flight.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
      </ProjectInner>
      <ImageContainerFull>
        <Img fluid={data.setup.childImageSharp.fluid} />
      </ImageContainerFull>
      <NextProject to="/massart" bg={colors['am-orange']} color={colors['am-orange-ally']}>
        MassArt
      </NextProject>
      <Footer />
    </Layout>
  </ThemeProvider>
)

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query {
    Tempo: file(relativePath: { eq: "projects/tempo.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    stuart: file(relativePath: { eq: "tempo/tempo-stuart.png" }) {
      childImageSharp {
        fixed(width: 165, height: 309) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    group: file(relativePath: { eq: "tempo/tempo-group.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ipad: file(relativePath: { eq: "tempo/tempo-ipad.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    using: file(relativePath: { eq: "tempo/tempo-using-demo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    setup: file(relativePath: { eq: "tempo/tempo-demo-setup.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
